import React from "react";
import { connect } from "react-redux";
import { PhenomLabel } from "../../util/stateless";
import {MergeErrorConfirm} from "./merge_error_confirm"
import LoaderButton from "../../widget/LoaderButton";
import PhenomId from "../../../requests/phenom-id";
import { ManageSubMenu } from "./model_manage";
import ChangeSetManager from "./sections/ChangeSetManager";
import NavTree from "../../tree/NavTree";
import * as actionCreators from "../../../requests/actionCreators";
import { _ajax, deleteMergeRequest, createReviewProject } from "../../../requests/sml-requests";
import { debounce, throttle } from "lodash";
import loadingIcon from "../../../images/Palette Ring-1s-200px.gif";
import { BasicAlert } from "../../dialog/BasicAlert";
import { KbButton } from "../../util/stateless";
import MergeSummary from "./merge_summary";
import ListPaneView from "../../edit/list-pane-view"
import { formatDate } from "../../util/util";
import { fetchMergeRequest } from "../../../requests/sml-requests"
import { BasicConfirm } from "../../dialog/BasicConfirm";
import { PhenomInput, PhenomTextArea } from "../../util/stateless";
import { Checkbox } from "@progress/kendo-react-inputs";
import { validateNodeFields } from "../../util/util";
import ReactTooltip from "react-tooltip";
import { isProjectLocked } from "../../../requests/sml-requests";

class Approve extends React.Component {
  
  summaryRef = React.createRef();
  listViewRef = React.createRef();
  
  constructor(props) {
    super(props);

    this.newReviewProject = {
      name: "",
      description: "",
      lockDstModel: true,
      requestId: null,
    }

    this.state = {
      loading: false,
      pendingRequests: [],
      completedRequests: [],
      deletedRequests: [],
      contentOverride: false,
      deprecateDeletes: false,
      deprecateMoves: false,
      fetchPendingItems: false,

      activeRequest: null,
      isReviewProject: false,
      lockable: false,
      reviewProject: {...this.newReviewProject}
    };
    
    this._isMounted = true;
    this.noticeRef = undefined;
    this.origBranchName = undefined;
    this.neededNodes = undefined;

    this.requiredFields = {
      ["name"]: {
        required: true,
        checkFirstChar: true,
        checkAllChars: true,
        errorRef: React.createRef(),
      },
    }
  }

  componentDidMount() {
    NavTree.collapseNavTree(false);
    this.fetchTreeNodes();
    this.getPendingItems();
    this.setLockable();
  }

  setLockable = () => {
    const {userIdentity, skaylAdmin} = this.props;
    if(!skaylAdmin) return;

    return isProjectLocked(userIdentity.branchId).then(res => {
      const disableLock = res.data?.locked_status;

      this.setState({lockable: !disableLock});
    });
  }

  componentDidUpdate(prevProps, prevState) {
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  fetchTreeNodes = () => {
    this.setState({ loading: true });

    NavTree.reset()
           .finally(() => {
              this.setState({ loading: false });
           })
  }

  // note: currently a push request can go through without the parent
  //       so on the approve page it may not render but this method can still select it.
  handleSelectAll = () => {
    NavTree.selectAllMergeCandidates();
  };

  selectNecessaryNodes = () => {
    NavTree.selectMergeCandidates( this.neededNodes.map(n => n.guid) );
  }

  selectSingleNode = (guid) => {
    NavTree.selectMergeCandidates([ guid ]);
  }

  toggleDeleteDeprecation = () => {
    this.setState({ deprecateDeletes: !this.state.deprecateDeletes})
  }

  toggleMoveDeprecation = () => {
    this.setState({ deprecateMoves: !this.state.deprecateMoves})
  }

  getPendingItems() {
    this.setState({ fetchPendingItems: true, loading: true });

    const customErrors = {
      500: "There was an error retrieving pending merge requests, please try again in a few minutes"
    }

    return _ajax({
      url: "/index.php?r=/referencing-model/pending-merge-requests",
    }, customErrors).then((res) => {
      const { pending, completed, deleted } = res.data;

      this.setState({
        pendingRequests: this.formatMergeRequests(pending),
        completedRequests: this.formatMergeRequests(completed),
        deletedRequests: this.formatMergeRequests(deleted),
      });
    }).always(() => {
      this.setState({
        fetchPendingItems: false,
        loading: false,
      });
    });
  }

  formatMergeRequests = (mergeRequests = []) => {
    let formattedMergeRequests = [...mergeRequests];

    // sort by completed or deleted or created date - do preliminary check
    if (formattedMergeRequests.some((mr) => !!mr.completed)) {
      formattedMergeRequests = formattedMergeRequests.sort((a, b) => {
        const dateA = new Date(a.completed);
        const dateB = new Date(b.completed);
        return dateB - dateA;
      });
      formattedMergeRequests.forEach((request => {
        request.completed = formatDate(request.completed, true);
        request.created = formatDate(request.created, true);
      }));

    } else if (formattedMergeRequests.some((mr) => !!mr.deleted)) {
      formattedMergeRequests = formattedMergeRequests.sort((a, b) => {
        const dateA = new Date(a.deleted);
        const dateB = new Date(b.deleted);
        return dateB - dateA;
      });
      formattedMergeRequests.forEach((request => {
        request.deleted = formatDate(request.deleted, true);
        request.created = formatDate(request.created, true);
      }));

    } else {
      formattedMergeRequests = formattedMergeRequests.sort((a, b) => {
        const dateA = new Date(a.created);
        const dateB = new Date(b.created);
        return dateB - dateA;
      });

      formattedMergeRequests.forEach((request => {
        request.created = formatDate(request.created, true);
      }));
    }

    return formattedMergeRequests;
  }

  setActiveRequest = (newRequest=null) => {
    const { activeRequest } = this.state;

    // new active commit is selected - set active
    if (newRequest?.id !== activeRequest?.id) {
      this.setState({
        activeRequest: newRequest ? {...newRequest} : null,
      });
      ReactTooltip.rebuild();
    // same active commit is selected - clear active
    } else {
      this.setState({
        activeRequest: null,
      });
    }
  }

  throttledSelectMergeRequest = throttle((request) => {
    const { activeRequest } = this.state;

    if (request.id === activeRequest?.id) {
      this.selectMergeRequest(null);
    } else {
      this.selectMergeRequest(request);
    }
  }, 100, { leading: true, trailing: false });

  selectMergeRequest = (request) => {
    if (request?.completed || request?.deleted) {
      return NavTree.reset().finally(() => {
        this.setActiveRequest(request);
        this.summaryRef.current && this.summaryRef.current.clearMergeSummary();
      });
    }
    else if (!request || !request.id ) {
      return NavTree.reset().finally(() => {
          this.setActiveRequest(null);
          this.summaryRef.current && this.summaryRef.current.clearMergeSummary();
        });
    }

    const { userIdentity } = this.props;

    BasicAlert.show("One moment please. Retrieving the needed data.", "Loading...", false);
    NavTree.reset()
    .finally(() => {
        this.summaryRef.current && this.summaryRef.current.clearMergeSummary();
        this.setState(
        { fetchPendingItems: true, 
          loading: true 
        }, () => {
          const requests = [fetchMergeRequest(request.id)];
          requests.push(NavTree.fetchDependencyMap("approve", userIdentity.branchId));
          Promise.all(requests)
                .then((res) => {
                  if (!res[0]?.data) return;
                  NavTree.addMergeCandidates(JSON.parse(res[0].data));
                  this.setActiveRequest(request);
                })
                .finally(() => {
                  this.summaryRef.current && this.summaryRef.current.sortMergeCandidates(NavTree.getAllMergeCandidates());
                  BasicAlert.hide();
                  this.setState({ loading: false, fetchPendingItems: false });
                })
        }
      );
    });
  }

  //resets the list of pending merges
  reset = () => {
    this.getPendingItems();
  }

  handleApprove = () => {
    const { activeRequest } = this.state

    if (!activeRequest || !activeRequest.id) return;

    const rejected = (errText) => {
      typeof errText === "string" && actionCreators.receiveErrors(errText);
    }

    const resolved = (response) => {
        if (response.status === "success") {
          NavTree.reset();
          actionCreators.receiveLogs("Merge Completed");
          this.summaryRef.current && this.summaryRef.current.clearMergeSummary();
          this.setActiveRequest(null);
          this.reset();
        } else {
          if(response.errors.length) {
            if (typeof response.errors[0] === 'string') {
              actionCreators.receiveErrors(response.errors);
            } else {
              const fixables = ["references", "unreachable"];
              const allFixable = response.errors.every(err => fixables.includes(err.method));
              if(allFixable) {
                this.setNeededNodes(response.errors);
                MergeErrorConfirm.show(this.neededNodes, this.selectNecessaryNodes, this.selectSingleNode);
              } else {
                actionCreators.receiveErrors(Array.from(new Set(response.errors.map(err => err.text))));
              }
            }
          } else {
            actionCreators.receiveErrors(["Failed to perform merge."]);
          }
        }
    }

    return NavTree.merge("approve", false, this.state.deprecateDeletes, this.state.deprecateMoves, false, false, activeRequest.id).then(resolved, rejected);
  }

  // pass in requestId or else gets grabbed from state
  handleDeleteRequest = (request) => {
    if (this.state.loading || !request || !request.id) return;

    BasicConfirm.show("Are you sure you want to delete this Merge Request?", 
      () => {
        this.setState({
          loading: true,
        });
        deleteMergeRequest(request.id).then((res) => {
          actionCreators.receiveResponse(res);
        }).always(() => {
          this.reset();
          this.selectMergeRequest(null);
          this.setState({loading: false});
        });
      }, 
     () => null
    );
  }
  
  // this logic is from the original code
  getAbsentParents = () => {
    NavTree.getSelectedMergeCandidates().forEach(leaf => {
      const parentLeaf = leaf.getParentLeaf();

      if (parentLeaf && parentLeaf.isMergeCandidate() && !parentLeaf.isMarkedForMerge()) {
        const node = {};
        node["name"] = parentLeaf.getName();
        node["guid"] = parentLeaf.getGuid();
        node["error_type"] = "PARENT_ABSENT";
        node["error_name"] = leaf.getName();
        node["error_guid"] = leaf.getGuid();
        node["failure"] = false
        this.neededNodes.push(node);
      }
    })
  }

  // this logic is from the original code
  //    - "neededNode" is a guid
  setNeededNodes(response) {
    this.neededNodes = [];
    response.filter(er => er.method === "references" || er.method === "unreachable").forEach((err, i) => {
        const neededGuid = err.value;
        const neededLeaf = NavTree.getLeafNode(neededGuid);

            const failure = !neededLeaf;
            const node = {};
            node["name"] = !failure ? neededLeaf.getName() : "Not Found";
            node["guid"] = neededGuid;
            node["error_type"] = err.method
            node["error_name"] = err.name;
            node["error_guid"] = err.guid;
            node["failure"] = failure;
            node["deleted"] = neededLeaf?.isDeletedForMerge();
            this.neededNodes.push(node);
    });
  }

  updateReviewProject = (key, value) => {
    this.setState(prevState => ({
        reviewProject: {
            ...prevState.reviewProject,
            [key]: value
        }
    }));
  }

  validateFields = () => {
    const { reviewProject, isReviewProject } = this.state;
    if(!isReviewProject) return true;

    return validateNodeFields(this.requiredFields, reviewProject);
  }

  handleCreateReviewProject = () => {
    if (!this.validateFields()) return actionCreators.receiveWarnings("Please fill in the missing fields.");
    const { reviewProject, activeRequest } = this.state;

    const requestMap = NavTree.getRequestMap();
    if (!requestMap) return actionCreators.receiveErrors("Please make a selection and try again.");

    this.setState({loading: true});

    const reviewProject2 = {
      ...reviewProject,
      requestId: activeRequest.id,
      merges: requestMap,
    }

    return createReviewProject(reviewProject2).then((res)=> {
      actionCreators.receiveResponse(res);
      this.selectMergeRequest(null);
      this.setState({
        reviewProject: {...this.newReviewProject},
        isReviewProject: false,
      });
      this.getPendingItems();
      this.setLockable();
    }).always(() => {
      this.setState({loading: false});
    });
  }

  renderPaneContent = () => {
    const { userIdentity, skaylAdmin } = this.props;
    const { activeRequest, isReviewProject, reviewProject, lockable, loading } = this.state;
    if (!activeRequest) return;
  
    const cinc_works = this.props.userRole.indexOf('c') != -1;
    return (
      <div className="p-col" style={{ gap: 0 }}>
        <div className="p-row">
          <div className="p-col p-col-1">
            <PhenomInput 
              label={"Merge Request"}
              disabled={true}
              autoFocus={true}
              value={activeRequest.name}
            />
            <PhenomTextArea 
              label="Description"
              disabled={true}
              value={activeRequest.description}
              style={{ minHeight: "50px" }}
              containerProps={{ style: { marginBottom: "5px" } }}
            />
          </div>
          <div className="p-col p-col-1" style={{ gap: 0 }}>
            <div>
              <div className="p-col" style={{ gap: 0 }}>
                <PhenomLabel text="Source" />
                <p>{activeRequest.src_projects}</p>
              </div>
              <div className="p-col" style={{ gap: 0 }}>
                <PhenomLabel text="Author" />
                <p>{activeRequest.author}</p>
              </div>
              <div className="p-col" style={{ gap: 0 }}>
                <PhenomLabel text="Creation Date" />
                <p>{activeRequest.created}</p>
              </div>
              {activeRequest.completed && (
                <div className="p-col" style={{ gap: 0 }}>
                  <PhenomLabel text="Completion Date" />
                  {activeRequest.completed || null}
                </div>
              )}
              {activeRequest.deleted && (
                <div className="p-col" style={{ gap: 0 }}>
                  <PhenomLabel text="Deletion Date" />
                  {activeRequest.deleted || null}
                </div>
              )}
            </div>
          </div>
        </div>
  
        {!activeRequest.completed && !activeRequest.deleted && (
          <>
            <div className="cadet-line" style={{ marginTop: "10px", marginBottom: "16px" }} />
  
            <div className="p-row">
              <div className="p-col p-col-1">
                <div className="p-col" style={{ gap: 7, marginBottom: "5px", color: "#565656" }}>
                  <div className="p-row">
                    <Checkbox 
                      label="Approve Deletions as Deprecations" 
                      checked={this.state.deprecateDeletes} 
                      onChange={this.toggleDeleteDeprecation} 
                    />
                  </div>
                  <div className="p-row">
                    <Checkbox 
                      label="Approve Node Moves as Deprecations" 
                      checked={this.state.deprecateMoves} 
                      onChange={this.toggleMoveDeprecation} 
                    />
                  </div>
                  {skaylAdmin && (
                    <div className="p-row" style={{ gap: 0, alignItems: "center" }}>
                      <Checkbox 
                        label="New Review Project" 
                        checked={isReviewProject} 
                        onChange={(e) => this.setState({ isReviewProject: e.value })}
                      />
                      <div style={{ display: "flex" }}>
                        <span className="fas fa-info-circle"
                                  style={{margin: "0px 0px 2px 5px"}}
                                  data-tip={`
                                    A review project is a temporary project allowing you to review the impacts of the merge before finalizing it.
                                    <br/><br/>
                                    Like any other projects, you can access it from the "Manage Projects" page or from the merge request it originated from.
                                    <br/><br/>
                                    When creating a review project, you have the ability to lock your current project (i.e., the project you are merging into). 
                                    This will prevent users from making any changes to it for the duration of the review. The project will be unlocked when you finalize the merge. 
                                    <br/>
                                    Note that it is possible to have multiple review projects (from different merge requests) based on the same project.
                                    Your project may be locked but it doesnt restrict the creation of additional review projects.`}
                                  data-for="review-project-info"
                                  data-html={true}
                                  data-place="right"/>
                        <ReactTooltip id='review-project-info' className="tool-tip"/>
                      </div>
                    </div>
                  )}
  
                  {isReviewProject && (
                    <div className="p-col" style={{ marginLeft: "20px", gap: 5 }}>
                      <div className="p-row" style={{ gap: 0, alignItems: "center" }}>
                        <Checkbox 
                          label="Lock Current Project During Review" 
                          checked={!lockable || reviewProject.lockDstModel} 
                          onChange={(e) => this.updateReviewProject("lockDstModel", e.value)} 
                          disabled={!lockable}
                        />
                        <span className="fas fa-info-circle"
                              style={{margin: "0px 0px 2px 5px"}}
                              data-tip={lockable ? `
                                Locking a project during the review process is strongly recommended to prevent any modifications to the merge destination.
                                This ensures that the destination project remains unchanged throughout the review. 
                                <br/><br/>
                                If changes are made while the destination project is under review, a content rebase will be necessary before finalizing the merge.
                                <br/><br/>
                                Once a project is locked, it cannot be locked again until the review is completed.
                                `
                                :
                                `The current project is already locked.`}
                              data-for="lock-info"
                              data-html={true}
                              data-place="right"/>
                        <ReactTooltip id='lock-info' className="tool-tip"/>
                      </div>
                      <div className="p-col">
                        <PhenomInput 
                          label={"Review Project Name"}
                          autoFocus={true}
                          value={reviewProject.name}
                          onChange={(e) => this.updateReviewProject("name", e.target.value)} 
                          config={this.requiredFields["name"]}
                        />
                        <PhenomTextArea 
                          label="Description"
                          style={{ minHeight: "50px" }}
                          value={reviewProject.description}
                          onChange={(e) => this.updateReviewProject("description", e.target.value)} 
                        />
                      </div>
                    </div>
                  )}
                  <div className="p-row" style={{ gap: 0 }}> 
                    <LoaderButton 
                      onClick={this.handleSelectAll} 
                      disabled={cinc_works} 
                      text="SELECT ALL" 
                      style={{ marginRight: "5px" }} 
                    />
                    <div>
                      <LoaderButton
                        text={isReviewProject ? "CREATE REVIEW PROJECT" : "APPROVE"}
                        onClick={() => isReviewProject ? this.handleCreateReviewProject() : this.handleApprove()}
                        disabled={loading || cinc_works}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-col p-col-1">
                <MergeSummary page={"approve"} ref={this.summaryRef} />
              </div>
            </div>
          </>
        )}
      </div>
    );
  }

  renderContentAboveList = () => {
    return (
      <div>
        <PhenomLabel text="Approve" />
        <p style={{marginBottom: 20}}>Select the nodes from the nav tree to pull and click Approve. Pulling is immediate and will overwrite your current nodes. This action cannot be undone.</p>
      </div>
    );
  }

  render() {
    const { skaylAdmin } = this.props;
    const { activeRequest, pendingRequests, completedRequests, deletedRequests, loading, fetchPendingItems } = this.state;
    const phenomId = new PhenomId("approve",this.props.idCtx);

    return <div className="phenom-content-wrapper" >
              <ManageSubMenu skaylAdmin={skaylAdmin}> 
                <KbButton />
              </ManageSubMenu>
              <MergeErrorConfirm idCtx={phenomId.gen()} />

              <div id={phenomId.gen("branch","wrapper")} className="branch-wrapper" style={{ flexGrow: 1, overflowY: "auto" }}>  
                <div id={phenomId.gen("","wrapper")}>
                  <ListPaneView
                    ref={this.listViewRef}
                    /* Data */
                    mainKey={"id"}
                    activeItem={activeRequest}
                    /* List */
                    listWidth={48}
                    renderContentAboveList={(this.renderContentAboveList)}
                    lists={[
                      { collapsible: false,
                        data: [...pendingRequests, ...completedRequests, ...deletedRequests],
                        headerOnly: true,
                        columns: [{header: "Merge Requests"}]},
                      { collapsible: true, 
                        collapsed: false,
                        deleteable: true,
                        data: pendingRequests, 
                        columns: [{header: "Pending Merge Requests", key: "name", subKey: "description", tagKey: "src_projects", flex: 10}, 
                                  {header: "Author", key: "author", flex: 3},
                                  {header: "Creation Date", key: "created", flex: 6},        
                        ]},
                      { collapsible: true, 
                        data: completedRequests, 
                        columns: [{header: "Completed Merge Requests", key: "name", subKey: "description", flex: 10, tagKey: "src_projects"}, 
                                  {header: "Author", key: "author", flex: 3},
                                  {header: "Completion Date", key: "completed", flex: 6}        
                        ]},
                      { collapsible: true, 
                        data: deletedRequests, 
                        columns: [{header: "Deleted Merge Requests", key: "name", subKey: "description", flex: 10, tagKey: "src_projects"}, 
                                  {header: "Author", key: "author", flex: 3},
                                  {header: "Deletion Date", key: "deleted", flex: 6},   
                        ]},
                    ]}
                    /* Pane */
                    renderPaneContent={this.renderPaneContent}
                    minimizePane={!activeRequest}
                    /* Config */
                    onSelect={this.throttledSelectMergeRequest}
                    onDelete={(item) => this.handleDeleteRequest(item)}
                  />
                </div>
              </div>
              {(fetchPendingItems || loading) &&
                <div style={{ display: 'flex', alignItems: 'center' }}> 
                  <img style={{ width: 80 }}src={loadingIcon} />
                  <p style={{ marginLeft: '10px' }}>Processing Request...</p>
                </div> }
            </div>
  }
}

const msp = (state) => ({
  userRole: state.user.userRole,
  userIdentity: state.user.userIdentity,
  skaylAdmin: state.user.skaylAdmin,
})


export default connect(msp)(Approve)
